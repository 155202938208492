.colorbox {
  a {
    color: $white;
    text-decoration: underline;
  }
}
.color-grey {
  background: #E6E4E4;
  color: $black;
  a {
    color: $black;
  }
}
.color-red {
  background: #9C1717;
  color: $white;
}
.color-blue {
  background: #1682BF;
  color: $white;
}
.color-green {
  background: #4F7E4D;
  color: $white;
}
.color-yellow {
  background: #EBBD4C;
  color: $black;
  a {
    color: $black;
  }
}