@media only screen and (min-width: 991px) {
  #mainnavi {
    background: $navi-bg-first;
    font-size: 2rem;
    ul {
      color: $white;
      display: flex;
      justify-content: flex-end;
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      position: relative;
      z-index: 20;

      li {
        align-items: center;
        display: inline-flex;
        a {
          color: inherit;
          padding: 0 23px;
          text-align: left;
          min-height: 7rem;
          align-items: center;
          display: flex;
          &.exp-header {
            display: none;
          }
          &:hover {
            background-color: $navi-bg-second;
            text-decoration: none;
          }

          &:focus {
            background-color: $navi-bg-second;
            //outline: 2px solid $red;
          }
        }

        &:nth-last-child(2) {
          a {
            margin-right: 30px;
          }
        }

        &.donation {
          margin-right: 0;
          text-transform: uppercase;

          a {
            align-items: center;
            background: $red;
            display: inline-flex;
            padding-left: 25px;
            padding-right: 25px;

            &:hover,
            &:focus {
              background: #C81D1D;
            }

            span {
              display: inline-block;
              font-size: 3.6rem;
              line-height: 2rem;
              margin-right: 17px;
            }
          }
        }
        /* 2nd lvl*/
        ul {
          display: none;
          li {
            display: flex;
            flex-basis: auto;
          }
        }

        &.active {
          /* 2nd lvl */
          ul {
            background: rgb(121, 144, 164);
            background: linear-gradient(180deg, rgba(121, 144, 164, 1) 70px, rgba(201, 212, 220, 1) 70px);
            /*background-color: $navi-hover;*/
            display: inline-flex;
            position: absolute;
            justify-content: flex-end;
            left: 0;
            top: 70px;
            width: 100%;
            text-align: right;
            li {
              margin-bottom: 500px;
              position: relative;

              a {
                &:hover,
                &:focus {
                  background: $navi-bg-third;
                  color: $black;
                }
              }

              /* 3d lvl */
              ul {
                background: transparent;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding-left: 25px;

                li {
                  margin-bottom: 0;
                  width: 100%;
                  display: flex;
                  flex-basis: 100%;

                  a {
                    color: #000;
                    padding: 10px 0;

                    &:hover,
                    &:focus {
                      background: transparent;

                      &:before {
                        content: url('../../../global/img/arrow-right-black.svg');
                        display: inline-block;
                        position: absolute;
                        left: -30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  #mainnavi {
    ul {
      li {
        /* 2nd lvl*/
        ul {
          display: none;

          li {
            display: flex;
            flex-basis: 20%;
          }
        }
      }
    }
  }
}

/*mobile Navi*/
@media only screen and (max-width: 991px) {
  .logo {
    margin: 35px auto!important;
    display: block;
  }
  #mainnavi {
    background: $navi-bg-first;
    font-size: 2rem;
    .navi-toggle-container {
      display: none;
      &.visible{
        display: block;
      }
    }
    /* 1st lvl */
    ul{
      color: $white;
      padding-left: 0;
      margin-bottom: 0;
      li{
        border-bottom: 1px solid $white;
        display: block;
        a{
          color: inherit;
          display: block;
          padding: 20px 30px;
          width: 100%;
          &.exp-header {
            position: relative;
            width: 5rem;
            span {
              top: .5rem;
              right: .5rem;
              position: absolute;
              display: none;
              width: 55px;
              &.active {
                display: block;
              }
            }
          }
        }
        /* 2d lvl */
        ul {
          background-color: $navi-bg-second;
          display: block;
          max-height: 0;
          overflow: hidden;
          transition: max-height .6s ease-out;
          &.active {
            display: block;
            height: auto;
            max-height: 1500px;
            transition: max-height .6s ease-in;
          }
          li {
            border-bottom: none;
            border-top: 1px solid $white;
            a:not(.exp-header) {
              padding-left: 50px;
            }
            /* 3d lvl */
            ul {
              background-color: $navi-bg-third;
              li {
                a:not(.exp-header) {
                  color: #000;
                  padding-left: 70px;
                }
              }
            }
          }
        }
        &.donation a{
          background: $red;
          text-align: center;
          text-transform: uppercase;
          span {
            display: none;
          }
        }
      }
    }
    &.active {
      overflow: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      -webkit-overflow-scrolling: touch;
      height: 100vh;
      -moz-scrollbar-width: none;
      scrollbar-width: none;
    }
  }
  .navbar-header {
    display: flex;
    justify-content: flex-end;
  }
  .navbar-toggle {
    background: none;
    border: none;
    padding: 20px;
  }
}


/*Sticky*/
/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  box-shadow: 0 4px 13px 0 rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + main{
  padding-top: 60px;
}

.logo-brand {
  opacity: 0;
  position: absolute;
  left: 32px;
  top: 10px;
  transition: 0.6s opacity;
  z-index: 20;
  img {
    height: 50px;
  }
}
@media only screen and (min-width: 991px) {
  .sticky .logo-brand {
    opacity: 100;
    transition: 0.6s opacity;
    z-index: 30;
  }
}

#metanavi {
  font-size: 1.8rem;
  line-height: 2.1rem;
  position: absolute;
  right: 300px;
  top: 15px;
  ul {
    color: $black;
    list-style: none;
    li {
      display: inline-block;
      padding: 0 10px;
      a {
        color: inherit;
      }
    }
  }
}

.icon-search-desktop {
  width: 22px;
  height: 23px;
}

/*Suche (mobil)*/
.search-mobile {
  width: 100%;
}
.icon-search-mobile {
  width: 22px;
  height: 23px;
  fill: #fff;
}
.mobile-search-form {
  height: 100%;
  position: relative;
  width: 100%;
  input {
    background: #F1F1F1;
    border: 0;
    height: 100%;
    left: 0;
    padding: 0 30px;
    position: relative;
    width: calc(100% - 70px);
  }
  .mobile-search-submit {
    background: #8E98AB;
    border: 0;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    width: 70px;
  }
}

.navi-youth {
  background: #1682BF;
  text-align: center;
}
.navi-meta-link {
  background: #F1F1F1;
  color: $black;
}

.social-media-links {
  background: #7990A4;
  display: flex;
  fill: $white;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 15px 70px;
  a:hover {
    fill: $red;
  }
}
.icon-sm {
  width: 35px;
}