.is_play,
.is_breake{
  background: transparent;
  border: 1px solid #d20000;
  font-size: 2rem;
  height: 26px;
  right: 0;
  position: absolute;
  top: 0;
  width: 26px;
  a{
    background: $white;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 15;
    span{
      position: absolute;
      left: 3px;
      top: -3px;
      font-size: 2rem;
    }
  }
}

.slick-slide {
  line-height: 1;
  position: relative;
  img {
    width: 100%;
  }
}

.slick-next,
.slick-prev {
  align-items: center;
  background-color: rgba(216,216,216,0.63);
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  z-index: 10;
}
.slick-next{
  right: 0;
}
.slick-prev{
  left: 0;
}

.slick-prev::before,
.slick-next::before {
  color: $black;
  //font-size: 5rem;
  line-height: 0;
  position: absolute;
  //top: 9px;
}

.slick-next::before {
  content: url("../../../global/img/icon_chevron_right.svg");
}

.slick-prev::before {
  content: url("../../../global/img/icon_chevron_left.svg");
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button::before {
  font-size: 1rem;
}

.slick-dots li.slick-active button::before {
  color: $white;
}

/*Slider Overlay*/
.slider-overlay {
  background: $red;
  position: absolute;
  bottom: 10%;
  z-index: 100;
  max-width: 590px;
  padding: 25px 45px;
  &.right {
    right: 10%
  }
  &.left {
    left: 10%
  }
  &.white {
    background: $white;
    color: $red;
  }
  &.red {
    background: $red;
    color: $white;
  }
  .headline {
    font-size: 5rem;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {
  .slider-overlay {
    display: block;
    left: 0!important;
    max-width: 100%;
    padding-bottom: 4rem;
    position: relative;
    width: 100%;
    .headline {
      font-size: 3rem;
      line-height: 3.3rem;
    }
  }
}