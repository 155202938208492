.accordion {
  .card {
    box-shadow: none;
    overflow: visible;
    &:hover {
      transform: none;
    }
    a {
      color: $red;
    }
    .card-header {
      background: $white;
      box-shadow: 14px 0 14px 0 rgba(0,0,0,0.12);
      font-size: 2.8rem;
      line-height: 3.4rem;
      margin-bottom: 7px;
      padding: 10px 20px;
      a {
        align-items: center;
        color: $red;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .card-body {
      font-size: 1.8rem;
      line-height: 2.5rem;
      padding: 10px 20px;
    }
    .toggle-icon {
      background: url("../../../global/img/icon_minus.svg") no-repeat;
      flex-shrink: 0;
      height: 16px;
      width: 16px;
    }
    .collapsed {
      .toggle-icon {
        background: url("../../../global/img/icon_plus.svg") no-repeat;
      }
    }
  }

}