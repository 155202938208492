/*Jugend/Kinder-Einstiegsseite*/
.youth-logo {
  background: #1682bf;
  padding: 20px 70px;
  width: 100%;
  a {
    display: flex;
    text-decoration: none;
    .logo-text {
      color: $white;
      display: flex;
      flex-direction: column;
      font-size: 2.4rem;
      justify-content: center;
      line-height: 2.8rem;
      margin-left: 20px;
    }
  }
}
.colored-border {
  background: url("../../../global/img/colored_border.svg");
  height: 100%;
}
.featured-program {
  align-content: center;
  background: rgba(22,130,191,0.6);
  color: $white;
  display: flex;
  flex-wrap: wrap;
  font-size: 3.2rem;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  line-height: 3.4rem;
  padding: 0 3rem;
  text-align: center;
  a {
    color: inherit;
  }
  img {
    margin: 2rem 0 4rem 0;
  }
  .icon-arrow {
    fill: $white;
    margin-top: 3rem;
    width: 6rem;
  }
}
@media only screen and (min-width: 1200px) {
  .featured-program {
    font-size: 6.2rem;
    line-height: 6.4rem;
  }
}

.hoverlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.hoverlay:hover {
  -webkit-box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
  box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
}

.square-100 {
  position: relative;
  width: 100%;
  padding-bottom : 100%; /* = width for a 1:1 aspect ratio */
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover; /* you change this to "contain" if you don't want the images to be cropped */
  overflow: hidden
}
.social-media-row {
  background-color: rgba(22,130,191,0.52);
  padding-bottom: 140px;
}
.social-media-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  fill: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  &.youtube {
    background: #B51818;
  }
  &.facebook {
    background: #45629B;
  }
  &.twitter {
    background: #62A6DD;
  }
  &.instagram {
    background-image: url("../../../global/img/instaBack.jpg");
    background-size: cover;
  }
  &:hover {
    -webkit-box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
    box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
  }
  .icon-sm {
    width: 100px;
  }
}