footer {
  background: $navi-bg-first;
  color: $white;
  font-size: 1.8rem;
  min-height: 300px;
  .copyright {
    margin-bottom: 2rem;
  }
}

.social-media-footer-links {
  fill: $white;
  padding: 100px 0 60px;
  a {
    padding-right: 2rem;
  }
}

.footer-navigation {
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    padding-right: 2rem;
    a {
      color: inherit;
    }
  }
}


.footer-youth {
  background: $light-blue;
  min-height: auto;
  .copyright{
    padding: 3rem 5rem;
  }
}