/* manrope-regular - latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('../../../global/fonts/manrope-v4-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../../global/fonts/manrope-v4-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../global/fonts/manrope-v4-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../global/fonts/manrope-v4-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
  url('../../../global/fonts/manrope-v4-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../global/fonts/manrope-v4-latin-ext-regular.svg#Manrope') format('svg'); /* Legacy iOS */
}

/* manrope-600 - latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: url('../../../global/fonts/manrope-v4-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../../global/fonts/manrope-v4-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../global/fonts/manrope-v4-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../global/fonts/manrope-v4-latin-ext-600.woff') format('woff'), /* Modern Browsers */
  url('../../../global/fonts/manrope-v4-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../global/fonts/manrope-v4-latin-ext-600.svg#Manrope') format('svg'); /* Legacy iOS */
}
