/* rechte Spalte */
aside {
  font-size: 1.6rem;
}

.element {
  margin-bottom: 5rem;
}

#content {
  font-size: 1.8rem;
  margin-bottom: 5rem;
  &.content {
    margin-top: 9rem;
  }
  &.content-no-space {
    margin-top: 0;
  }
}

/*Vorspann*/
.lead {
  font-weight: 700;
}

/* Links */
a {
  color: $red;
}
a:hover,
a:focus {
  color: $red;
}
.link {
  font-size: 1.8rem;
  line-height: 2.5rem;
}
.center-link {
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin: 4rem 0;
  text-align: center;
  &:after {
    content: url('../../../global/img/arrow-right.svg');
    display: inline-block;
    width: 18px;
  }
}
.backlink {
  color: $black;
  display: block;
  font-size: 3.6rem;
  line-height: 4.3rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  &:before {
    content: url('../../../global/img/arrow-right-black.svg');
    display: inline-block;
    padding-left: 15px;
    transform: rotate(180deg) translate(0, -8px);
  }
}
.download-link {
  &:before {
    content: url('../../../global/img/icon_calendar.svg');
    display: inline-block;
    padding-right: 15px;
    transform: translate(0, 4px);
  }
}

.link-download {
  &:before {
    content: url('../../../global/img/icon_download.svg');
    display: inline-block;
    padding-right: 10px;
    position: relative;
    top: 3px;
  }
}
.link-file {
  &:before {
    background: $red;
    color: #fff;
    display: inline-block;
    height: 27px;
    margin-right: 15px;
    text-align: center;
    width: 65px;
  }
}
.link-pdf {
  &:before {
    content: "PDF";
  }
}
.link-doc {
  &:before {
    content: "DOC";
  }
}
.link-zip {
  &:before {
    content: "ZIP";
  }
}
.link-jpg {
  &:before {
    content: "JPG";
  }
}
.link-docx {
  &:before {
    content: "DOCX";
  }
}
.link-xls {
  &:before {
    content: "XLS";
  }
}
.link-xlsx {
  &:before {
    content: "XLSX";
  }
}
.link-mp3 {
  &:before {
    content: "MP3";
  }
}
.link-mp4 {
  &:before {
    content: "MP4";
  }
}


/* Article Content */
.kicker {
  color: $black;
  display: block;
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  span {
    display: block;
  }
}
p {
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

/* Events */
.event-image {
  margin-bottom: 4rem;
}
.event-infos {
 > div:not(.contact){
    border-top: 1px solid #979797;
  }
  p {
    font-size: inherit;
  }
  p:last-child {
    margin: 0;
  }
}

/*Teaser rechte Spalte*/
aside .teaser {
  border-bottom: 1px solid #979797;
  line-height: 2.2rem;
  div {
    padding: 8px 0;
  }
  img {
    display: block;
    margin: 10px 0 30px 0;
    max-width: 100%;
  }
  .teaser-item {
    img {
      margin-bottom: 10px;
      margin-top: 0;
    }
  }
}
.lead {
  font-size: 1.8rem;
}
.event-infos,
.sponsor,
.partner {
  margin-bottom: 2.5rem;
}

/*Images*/
.caption {
  font-size: 1.4rem;
  line-height: 2.5rem;
}

/*Colorboxes*/
.colorbox {
  padding: 4rem 5rem 2rem 5rem;
  margin: 0 0 5rem 0;
}

/*Blockquote*/
blockquote {
  align-items: flex-start;
  background: #E6E4E4;
  display: flex;
  padding: 3.5rem 4rem;
  .cite {
    margin-left: 3.5rem;
  }
  p {
    font-size: 3rem;
    line-height: 1.2;
    hyphens: auto;
  }
  .bq-footer {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
}

/*Kontakt*/
.contact {
  box-shadow: 14px 0 14px 0 rgba(0,0,0,0.12);
  margin-bottom: 25px;
  padding: 15px;
  width: 100%;
  .contact-data {
    font-size: 1.4rem;
    padding: 0 25px;
    .strong {
      font-size: 1.6rem;
      font-weight: 700;
    }
    .name {
      margin: 15px 0;
    }
    div {
      border: 0;
      padding: 0;
    }
  }
  img {
    height: 174px;
    margin-bottom: 0;
    width: 174px;
  }
  display: flex;
}

/*Kontakt-Teaser Portrait-Modus*/
.contact.portait-mode {
  display: flex;
  flex-wrap: wrap;
  .contact-data {
    padding: 0;
  }
  img {
    height: auto;
    margin-bottom: 15px;
    width: 100%;
  }
}
/*Kontakt-Teaser, Portrait-Modus -> rechte Spalte*/
aside .contact.portait-mode {
  box-shadow: none;
  padding: 0;
  .contact-data {
    font-size: 1.6rem;
  }
}

/*Listen*/
ol {
  list-style: none;
  counter-reset: li;
  padding: 0;
  li {
    counter-increment: li;
    &:before {
      content: counter(li) ". ";
      color: $red;
      display: inline-block;
      margin-left: -1.5rem;
      padding-right: 0.5rem;
      text-align: right;
      width: 3.3rem;
    }
  }
}

.element ul {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.5rem;
    position: relative;
    &:before {
      color:$red;
      display: inline-block;
      content: url("../../../global/img/icon_circle.svg");
      left: 3px;
      margin-left: -1.5rem;
      padding-right: 7px;
      position: relative;
      top: -3px;
      width: 18px;
    }
  }
}

ul.linklist {
  li {
    &:before {
      content: url("../../../global/img/arrow-right.svg");
      left: 0;
      top: -2px;
      padding-right: 7px;
    }
  }
}

/*Videos responsive einbinden*/
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*Soundcloud*/
.audio-container {
  position: relative;
}