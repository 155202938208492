.card {
  border: 0;
  box-shadow: 14px 0 14px 0 rgba(0,0,0,0.12);
  width: 100%;
  transition: 0.3s all;
  a,
  a:hover,
  a:focus {
    color: $black;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    cursor: pointer;
    .card-link {
      text-decoration: underline;
    }
  }
  &:hover {
    transform: translate(0,-5px);
  }
}
.card-img-top {
  margin-bottom: 2.5rem;
}
.card-body {
  padding: 0 35px 80px 35px;
}
.card-kicker {
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}
.card-title {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.4rem;
  margin-bottom: 3rem;
}
.card-text {
  font-size: 1.8rem;
  line-height: 2.5rem;
}
.card-link {
  color: $red;
  font-size: 1.8rem;
  line-height: 2.1rem;
  position: absolute;
  bottom: 3.7rem;
}

/*Kicker-Icons*/
.kicker-icon {
  padding-left: 40px;
  position: relative;
}
.kicker-icon:before {
  display: inline-block;
  padding-right: 10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
}
.icon-gallery:before {
  content: url('../../../global/img/icon_image.svg');
}
.icon-video:before {
  content: url('../../../global/img/icon_play-circle.svg');
}
.icon-podcast:before {
  content: url('../../../global/img/icon_mic.svg');
}


.card-project {
  margin-bottom: 2.8rem;
  .card-body {
    padding: 0 35px;
  }
  a {
    text-decoration: none;
    color: $black;
    .card-title {
      text-align: center;
      transition: 0.6s ease;
    }
    .card-overlay{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transition: .6s ease;
      background: linear-gradient(180deg, rgba(202,0,0,0.57) 0%, #5D0000 100%);
    }
    &:hover {
      .card-overlay {
        opacity: 1;
      }
      .card-title {
        color: $white;
        position: relative;
        z-index: 10;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-item {
    padding: 0;
  }
}


.card-youth {
  border: 0;
  box-shadow: none;
  width: 100%;
  transition: 0.3s all;
  a,
  a:hover,
  a:focus {
    color: $black;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    cursor: pointer;
    .card-link {
      text-decoration: underline;
    }
  }
  &:hover {
    -webkit-box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
    box-shadow: inset 15px 15px 25px 0px rgba(0,0,0,0.5);
    transform: none;
  }
  .card-img-top {
    margin-bottom: 0;
  }
  .card-body {
    padding: 2rem 3.5rem;
  }
}

.card-row-1 {
  .card-body {
    background: $white;
  }
  .card-item:nth-child(2n) {
    .card-body {
      background: $light-grey;
    }
  }
}
.card-row-2 {
  .card-body {
    background: $white;
  }
  .card-item:nth-child(2n+1) {
    .card-body {
      background: $light-grey;
    }
  }
}