.logo {
  margin: 35px 0 35px 65px;
}

.youth-button {
  background: #1682BF;
  color: #fff;
  font-size: 2rem;
  line-height: 2.3rem;
  padding: 15px 25px 14px;
  position: absolute;
  right: 0;
  top: 0;
  &:before {
    content: url("../../../global/img/color-block.svg");
    position: absolute;
    left: -58px;
    top: 0;
  }
}