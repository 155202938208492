h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.headline {
  display: block;
}

.headline {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.4rem;
  margin-bottom: 2.5rem;
}

h1,
.h1 {
  font-size: 3.6rem;
  line-height: 4.3rem;
  margin-bottom: 4rem;
  .headline {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
  }
}

h2,
.h2 {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.6rem;
  margin-bottom: 2.5rem;
  .headline {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
  }
}

h3,
.h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 1.8rem;
  .headline {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
  }
}



aside {
  .headline {
    font-size: 2.4rem;
    line-height: 2.5rem;
    font-weight: 600;
  }
}