.gallery-image {
  margin-bottom: 30px;
  .overlay {
    color: $white;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .6s ease;
    background: linear-gradient(180deg, rgba(202,0,0,0.57) 0%, #5D0000 100%);
    justify-content: center;
    align-items: center;
  }
  a {
    display: flex;
    position: relative;
  }
  a:hover,
  a:focus {
    .overlay {
      opacity: 1;
    }
  }
}


/*MagnificPopup*/
.mfp-title {
  font-size: 24px;
  line-height: 25px;
  padding-right: 0;
  text-align: right;
}
img.mfp-img {
  padding: 60px 0 60px;
}
.mfp-close svg {
  fill: white;
}
.mfp-counter {
  display: none;
}