.filter {
  margin-bottom: 4rem;
  a {
    padding: 0 1.5rem;
  }
  .active {
    &:after {
      content:'';
      background: url("../../../global/img/icon_check.svg") no-repeat;
      display: inline-block;
      height: 15px;
      margin-left: 5px;
      width: 20px;
    }
  }

}
.standard-list {
  border-top: 1px solid $grey;
}
.standard-list-item {
  border-bottom: 1px solid $grey;
  padding: 3rem 0;
  .headline {
    margin-bottom: 1.5rem;
  }
  a,
  a:hover,
  a:focus{
    color: $black;
    text-decoration: none;
    .more-link {
      color: $red;
    }
  }
  a:hover,
  a:focus {
    .more-link {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 768px) {
  .standard-list-img {
    margin-bottom: 1.5rem;
  }
}