form #category {
  display: none;
}

.form-control {
  height: calc(2.4em + 1.2rem + 3.2px);
  padding: 0.6rem 1.2rem;
  font-size: 1.6rem;
  border-radius: 0.4rem;
}

.btn-lg {
  padding: .8rem 1.6rem;
  font-size: 2rem;
  border-radius: .48rem;
}