:root {
  font-size: 62.5%; /* (62.5/100) * 16px = 10px */
  --font-size--small: 1.4rem; /* 14px */
  --font-size--default: 1.6rem; /* 16px */
  --font-size--large: 2.4rem; /* 24px */
}

.font-size--small {
  font-size: var(--font-size--small);
}

.font-size--default {
  font-size: var(--font-size--default);
}

.font-size--large {
  font-size: var(--font-size--large);
}

/*Focus visible*/
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
.focus-visible{
  outline: 2px solid $red;
}


/*FONT*/
body {
  font-family: 'Manrope', sans-serif;
  font-width: 400;
}

.img-fluid {
  width: 100%;
}